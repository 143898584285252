import axios from 'axios'
import { orderBy } from 'lodash'
import { eachDay, format } from 'date-fns'

import config from '../../config.json'
const API_SOURCE = `${config.backend_server_url}_api/schedule/`

const smallestDate = (date1, date2) => {
  if (!date1) {
    return date2
  }

  const num1 = Number(date1.split('-').join(''))
  const num2 = Number(date2.split('-').join(''))

  return num1 > num2 ? date2 : date1
}

const largestDate = (date1, date2) => {
  if (!date1) {
    return date2
  }

  const num1 = Number(date1.split('-').join(''))
  const num2 = Number(date2.split('-').join(''))

  return num1 < num2 ? date2 : date1
}

export default async function getScheduleTableV2() {
  try {
    const response = await axios
      .all([
        axios.get(`${API_SOURCE}v2/schedule-table/?format=json`),
        axios.get(`${API_SOURCE}v2/vessels/?format=json`),
      ])
      .then(
        axios.spread(({ data: table }, { data: vessels }) => {
          // Map only boats that are available in the schedule table.
          const boats = orderBy(
            Object.values(table).map(vessel =>
              vessels.find(({ imo }) => `${imo}` === `${vessel.vessel_imo}`),
            ),
            ['sortkey'],
          ).filter(item => item !== undefined)

          if (!table || !vessels) {
            return { error: true }
          }

          // Table first and last day
          const { min, max } = Object.values(table).reduce((results, item) => {
            const dates = item.schedule.map(
              vesselSchedule => vesselSchedule.date,
            )
            const min = dates[0]
            const max = dates[dates.length - 1]

            return {
              min: smallestDate(results.min, min),
              max: largestDate(results.max, max),
            }
          }, {})

          // Function for fetching port-calls from a vessel schedule.
          const getPortCallsForDate = (vesselSchedule, date) => {
            for (let schedule of vesselSchedule) {
              if (schedule.date === date) {
                return schedule.port_calls
              }
            }
            return []
          }

          // Loop through and make the table
          const datesBetween = eachDay(new Date(min), new Date(max))
          const days = datesBetween.map(date => {
            const data = boats.map(({ imo }) => {
              let vesselData = []
              for (let vessel of table) {
                if (vessel.vessel_imo.toString() === imo) {
                  let portCalls = getPortCallsForDate(
                    vessel.schedule,
                    format(date, 'YYYY-MM-DD'),
                  )
                  if (portCalls !== []) {
                    for (let portCall of portCalls) {
                      vesselData.push({
                        voyage: portCall.voyage,
                        port: portCall.port_name,
                        portCode: portCall.port_locode,
                        eta: portCall.eta,
                        ets: portCall.etd,
                        closingList: Array.isArray(portCall.closing)
                          ? portCall.closing
                          : [],
                      })
                    }
                  }
                }
              }
              return vesselData
            })

            return {
              date,
              data,
            }
          })

          return {
            scheduleData: { boats, days },
            updated: null,
          }
        }),
      )
      .catch(error => {
        return { error: error.response.status }
      })

    return response
  } catch (error) {
    return { error: true }
  }
}
