import './index.scss'

import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import BEMHelper from 'react-bem-helper'
import ReactGA from 'react-ga'
import {
  format,
  isSameISOWeek,
  lastDayOfISOWeek,
  startOfISOWeek,
  isSameDay,
  compareAsc,
} from 'date-fns'

import debounce from '../../helpers/debounce'

import Icon from '../Icon'

const bem = new BEMHelper('schedule-table')

export default class ScheduleList extends Component {
  static propTypes = {
    data: PropTypes.object.isRequired,
    updated: PropTypes.object,
  }

  constructor() {
    super()

    this.state = {
      sticky: false,
      expanded: [],
      cellWidths: null,
      loaded: false,
    }

    this.scrollTicking = false
  }

  componentDidMount() {
    window.addEventListener('scroll', this.onScroll)
    window.addEventListener('resize', this.handleResize)

    this.updateWidths()

    setTimeout(this.checkSticky, 200)
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.onScroll)
    window.removeEventListener('resize', this.handleResize)
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (
      JSON.stringify(nextState.cellWidths) !==
        JSON.stringify(this.state.cellWidths) ||
      nextState.sticky !== this.state.sticky ||
      nextState.expanded.length !== this.state.expanded.length ||
      JSON.stringify(nextProps.data).length !==
        JSON.stringify(this.props.data).length
    ) {
      return true
    }

    return false
  }

  handleResize = debounce(() => {
    this.updateWidths()
    this.checkSticky()
  }, 300)

  updateWidths = () => {
    const rowClass = `.${bem('day').className}`
    const cellClass = `.${bem('item').className}`
    const firstRow = document.querySelector(rowClass)

    if (!firstRow) {
      return
    }

    const cells = [].slice.call(firstRow.querySelectorAll(cellClass))

    const { boats } = this.props.data

    this.setState({
      cellWidths: cells.map(({ offsetWidth }) => offsetWidth),
      loaded:
        this._table.offsetWidth > this._wrapper.offsetWidth || boats.length > 8,
    })
  }

  onScroll = () => {
    if (!this.scrollTicking) {
      window.requestAnimationFrame(this.checkSticky)
    }

    this.scrollTicking = true
  }

  checkSticky = () => {
    this.scrollTicking = false

    const scrolledPast = this.stickyHeader.getBoundingClientRect().top === 0
    const sticky = scrolledPast

    if (this.state.sticky !== sticky) {
      this.setState({ sticky })
    }
  }

  toggle = id => () => {
    const expanded = [...this.state.expanded]

    const index = expanded.indexOf(id)

    if (expanded) {
      // Log toggeling in Google Analytics
      ReactGA.event({
        category: 'Schedule Table',
        action: 'Open toggle stop details',
      })
    }

    if (index > -1) {
      expanded.splice(index, 1)
    } else {
      expanded.push(id)
    }

    this.setState({ expanded })
  }

  isExpanded = id => {
    const { expanded } = this.state
    const index = expanded.indexOf(id)

    return index > -1
  }

  renderClosing(closingList) {
    let closingEntries = []
    for (let closing of closingList) {
      closingEntries.push(
        <span key={`closing-${closing.terminal}`} {...bem('detail')}>
          Closing:
          <time {...bem('value')}>
            {format(closing.closing_datetime, 'D. MMM HH:mm')}
            {` (${closing.terminal})`}
          </time>
        </span>,
      )
    }
    return closingEntries
  }

  renderDetails = ({ port, id, date }) => {
    if (this.isExpanded(id)) {
      const getFormat = d => (isSameDay(d, date) ? 'HH:mm' : 'HH:mm (D. MMM)')
      return (
        <div {...bem('details')}>
          <button type="button" {...bem('close')} onClick={this.toggle(id)}>
            <Icon icon="x" />
          </button>

          {port.eta && (
            <span {...bem('detail')}>
              ETA:
              <time {...bem('value')}>
                {format(port.eta, getFormat(port.eta))}
              </time>
            </span>
          )}
          {port.ets && (
            <span {...bem('detail')}>
              ETS:
              <time {...bem('value')}>
                {format(port.ets, getFormat(port.ets))}
              </time>
            </span>
          )}
          {port.voyage && (
            <span {...bem('detail')}>
              Voy.&nbsp;no:
              <strong {...bem('value')}>{port.voyage}</strong>
            </span>
          )}
          {port.closingList && this.renderClosing(port.closingList)}

          {port.extra &&
            port.extra.map(item => (
              <div key={item.voyage} {...bem('extra-details')}>
                {item.eta && (
                  <span {...bem('detail')}>
                    ETA:
                    <time {...bem('value')}>
                      {format(item.eta, getFormat(item.eta))}
                    </time>
                  </span>
                )}
                {item.ets && (
                  <span {...bem('detail')}>
                    ETS:
                    <time {...bem('value')}>
                      {format(item.ets, getFormat(item.ets))}
                    </time>
                  </span>
                )}
                {item.voyage && (
                  <span {...bem('detail')}>
                    Voy.&nbsp;no:
                    <strong {...bem('value')}>{item.voyage}</strong>
                  </span>
                )}
                {item.closingList && this.renderClosing(item.closingList)}
              </div>
            ))}
        </div>
      )
    }
    return null
  }

  renderHeaderItem = ({ type, ...props }) => ({ name, trade }, index) => {
    const { cellWidths } = this.state

    const Node = type || 'div'
    const styles = {}

    if (type !== 'th' && cellWidths) {
      styles.width = `${cellWidths[index]}px`
    }

    return (
      <Node key={name} {...bem('boat-header')} style={styles} {...props}>
        <div {...bem('boat-name')}>
          {index === 0 && <span {...bem('boat-name-dummy')} />}
          <span {...bem('bullet')}>
            <Icon icon="shipfront" {...bem('boat-icon')} thin />
          </span>
          {name}

          {trade && <span {...bem('tooltip')}>{trade}</span>}
        </div>
      </Node>
    )
  }

  renderCell = (key, date) => (ports, index) => {
    const items = Object.values(
      ports.reduce((results, port) => {
        results[port.portCode] = [...(results[port.portCode] || []), port]
        return results
      }, {}),
    )
      .map(items => {
        if (items.length === 1) {
          return items[0]
        }
        if (items.length > 1) {
          return {
            ...items[0],
            extra: items.filter((item, index) => index > 0),
          }
        }
      })
      .sort((a, b) => compareAsc(a.eta, b.eta))
    return (
      <td key={index} {...bem('item')}>
        <ul {...bem('ports')}>
          {items.map((port, portIndex) => {
            const id = `${key}-${index}-${portIndex}`
            return (
              <li key={id} {...bem('port')}>
                <button
                  type="button"
                  {...bem('toggle', {
                    expanded: this.isExpanded(id),
                  })}
                  onClick={this.toggle(id)}
                >
                  {port.port}

                  <Icon
                    icon="chevron"
                    direction="down"
                    {...bem('toggle-icon')}
                  />
                </button>

                {this.renderDetails({ port, id, date })}
              </li>
            )
          })}
        </ul>
      </td>
    )
  }

  render() {
    const { sticky, loaded } = this.state
    const { updated } = this.props
    const { boats, days } = this.props.data

    // Group days into weeks
    let previous = { date: null }
    const weeks = days.reduce((results, item) => {
      if (!isSameISOWeek(item.date, previous.date)) {
        results.push([item])
      } else {
        results[results.length - 1].push(item)
      }

      previous = item

      return results
    }, [])

    return (
      <Fragment>
        <div
          {...bem('', { loaded, wide: boats.length > 6 })}
          ref={ref => {
            this._wrapper = ref
          }}
        >
          <div
            {...bem('header', {
              sticky: true,
              'is-sticky': sticky,
            })}
            ref={ref => {
              this.stickyHeader = ref
            }}
          >
            <div {...bem('date', 'background')} />
            {boats.map(this.renderHeaderItem({ type: 'div' }))}
          </div>

          <div {...bem('wrapper')}>
            <table
              {...bem('table')}
              ref={ref => {
                this._table = ref
              }}
            >
              <thead>
                <tr {...bem('header')}>
                  <th {...bem('date', 'background')} />
                  {boats.map(
                    this.renderHeaderItem({ type: 'th', scope: 'col' }),
                  )}
                </tr>
              </thead>

              <tbody>
                {weeks.map((week, weekIndex) => (
                  <Fragment key={`week-${week[0].date}`}>
                    <tr>
                      <th
                        scope="col"
                        colSpan={boats.length + 1}
                        {...bem('week')}
                      >
                        <strong {...bem('week-content')}>
                          Week {format(week[0].date, 'W')}
                        </strong>
                      </th>
                    </tr>

                    {week.map(({ date, data }, dayIndex) => (
                      <tr
                        key={format(date, 'YYYY-MM-DD')}
                        {...bem('day', {
                          'last-day-of-week': isSameDay(
                            lastDayOfISOWeek(date),
                            date,
                          ),
                          today: isSameDay(new Date(), date),
                        })}
                      >
                        <th
                          scope="row"
                          {...bem('date', {
                            'first-day-of-week': isSameDay(
                              startOfISOWeek(date),
                              date,
                            ),
                          })}
                        >
                          <span {...bem('day-name')}>
                            {format(date, 'ddd')}
                          </span>
                          {format(date, 'D. MMM')}
                        </th>

                        {data.map(
                          this.renderCell(`${weekIndex}-${dayIndex}`, date),
                        )}
                      </tr>
                    ))}
                  </Fragment>
                ))}
              </tbody>
            </table>
          </div>
        </div>

        {updated && (
          <p {...bem('last-updated')}>
            Last updated: {format(updated, 'D. MMM, HH:mm')}
          </p>
        )}
      </Fragment>
    )
  }
}
