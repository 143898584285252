import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

import Hero, { Overlap } from '../../components/Hero'
import Link from '../../components/Link'
import Button from '../../components/Button'
import ScheduleTable from '../../components/ScheduleTable'
import Message from '../../components/Message'
import Article from '../../components/Article'
import ErrorHeader from '../../components/ErrorHeader'
import Loader from '../../components/Loader'
import RenderContent from '../../components/RenderContent'
import imageHelper from '../../helpers/image-helper'
import getScheduleTableV2 from '../../helpers/get-schedule-table-v2'

export default class Schedule extends Component {
  state = {
    scheduleData: null,
    updated: null,
    error: null,
  }

  async componentDidMount() {
    const scheduleData = await getScheduleTableV2()

    this.setState(scheduleData)
  }

  render() {
    const { data } = this.props
    const { heroImage, title, preamble } = data.ievv || {}
    const { scheduleData, updated, error } = this.state

    if (!data.ievv) {
      return <ErrorHeader />
    }

    const getImage = imageHelper(data)
    const hero = heroImage ? getImage(heroImage) : {}

    return (
      <>
        <Hero {...hero} title={title} preamble={preamble} />

        <Overlap>
          {scheduleData && (
            <ScheduleTable data={scheduleData} updated={updated} />
          )}
          {!scheduleData && !error && <Loader />}
          {error && (
            <Message title="Something went wrong">
              Something is currently not working. Try{' '}
              <Button
                link
                onClick={() => {
                  window.location.reload()
                }}
              >
                reloading
              </Button>
              . If the problem persists, please{' '}
              <Link to="/about/contact/">contact us</Link>. We're sorry for the
              inconvenience. <strong>({error})</strong>
            </Message>
          )}
        </Overlap>

        <Article>
          <RenderContent data={data} />
        </Article>
      </>
    )
  }
}

Schedule.propTypes = {
  data: PropTypes.object.isRequired,
}

export const query = graphql`
  query {
    ievv(pagetypeid: { eq: "ncl_backend_pageframework.ScheduleTable" }) {
      ...ArticleData
    }
    ...GetImages
  }
`
